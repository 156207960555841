.spinner2 {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 9999;
    //background-color: #f5f5f5b8;
  }
  
  .spinner2 > div {
    margin-top: 10px;
  }