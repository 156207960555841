.appLayout{

  .mb10{
    margin-bottom: 10px;
  }

  .mr10{
    margin-right: 10px;
  }

  .rowmb0{
    margin-bottom: 0px;
  }

  .red{
    background-color: #f03a3a;
  }

  .green{
    background-color: #30bc5e !important;
  }

  .yellow{
    background-color: #f4cf2b !important;
  }

  .orange{
    background-color: #f68e18 !important;
  }

  .white{
    background-color: #ffffff !important;
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn{
    color: #002d62;
  }

  .ant-tabs .ant-tabs-ink-bar {
    background: #002d62;
}

  .menuItems{

    .ant-menu{
      background: #002d62;
      color: white;

      .ant-menu-item-selected{
        background-color: #7190a7b8;
        color: #ffffff;
      }

      .ant-menu-item-active:hover{
        background-color: #13447dcc;
        color: #ffffff;
      }   
    }

  }


  .header{

  .usernameMenu{
    .ant-menu-item-selected, .ant-menu-item {
      background: none;
    }

  }
   
  .ant-menu-horizontal{
    background-color: #efefef;
    color: rgb(24, 24, 24);
  }

  .alignHeaderItems{
    display: flex;
    justify-content: flex-end;
  }

  }

  .pageHeader{
    background: #f0f6fb;
    margin-bottom: 20px;

    .pageTitle{
      margin: 20px 20px 20px 10px;
    }
  }

  .ant-layout-header {
    background: #efefef;
}

  .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 10px 35px 0px 0;
   
  }
  
  .ant-row-rtl .logo {
    float: right;
    margin: 16px 0 16px 24px;
  }
  
  .site-layout-background {
    background: #fff;
  }


}

.loginPage{
  height: calc(100vh - 50px); 
  background: linear-gradient(0deg, rgba(255,255,255,1) 41%, rgba(198,242,255,1) 100%);

  .ant-card{
    width: 40%;
  }

  .ant-card-cover{
    padding: 70px;
  }

  .justifyCenter{
    display: flex;
    justify-content: center;
  }

  .ant-card-meta-title{
    text-align: center;
  }

  .litems{
    .ant-space-item{
      display: flex;
    justify-content: center;
    }
  }


  .ant-tabs-nav-wrap{
    display: flex;
    justify-content: center;
  }

  .ant-btn-block{
     width: 100%;
  }

  
@media all and (min-width:569px) and (max-width: 768px) {
  .ant-card{
    width: 80%;
  }
}

@media all and (min-width:481px) and (max-width: 568px) {
  .ant-card{
    width: 80%;
  }
}

@media all and (min-width:321px) and (max-width: 480px) {
  .ant-card{
    width: 80%;
  }
}

@media all and (min-width:0px) and (max-width: 320px) {
  .ant-card{
    width: 80%;
  }
}

}

.ant-modal .ant-modal-header {
  border-bottom: 1px solid #e7e7e7;
}

.justifyRight {
  display: flex;
  justify-content: flex-end;
}

.justifyLeft {
  display: flex;
  justify-content: flex-start;
}

.justifySpaceBetween {
  display: flex;
  justify-content: space-between;
}

.ant-row {
    margin-bottom: 20px;
}