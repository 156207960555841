
.statisticsForm {
    .ant-form-item{
        margin-bottom: 0px;
    }

    .ant-row {
        margin-bottom: 0px;
    }

  }
