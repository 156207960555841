.usersWrappers {

    .userFilters {

        .filterButtons {
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
        }

        .ant-form-item{
            margin-bottom: 0px;
          }
    }
    
}

.update-salva-button {
    display: flex;
    justify-content: flex-end;
}

