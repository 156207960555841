.techniciansWrappers {

    .techniciansFilters {
  
      .filterButtons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
  
      .ant-form-item {
        margin-bottom: 0px;
      }
  
    }
  
    }