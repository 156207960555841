
.notAuth { 


  .ant-page-header-heading-title{
    color: rgb(0, 0, 0);
  }

  .textNF{
    margin-bottom: auto;
    margin-top: auto;
  }
}
