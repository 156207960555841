.warrantyWrappers{

    
      .warrantyFilters{

        .filterButtons{          
            display: flex;
            flex-direction: row;
            justify-content: flex-end;  
          }

          .ant-form-item{
            margin-bottom: 0px;
          }

    }

    .warrantyDetails {
        .ant-divider {
            margin: 10px 0;
        }

        .privacyItem{            
                width: 100%;
                .ant-space-item{
                    width: 100%;
                }

                .collapseSpace{
                   width: auto;
                }               
            
        }

       
    }
}

#editWarranty{
  .ant-form-item-control-input-content{
    .ant-input-disabled, .ant-select-selector, .ant-picker .ant-picker-input >input[disabled]{
      color: #656565;
      background-color: #f4f4f4;
    }
  }
}

.newWarrantyForm{
  .ant-form-item-control-input-content{
    .ant-input-disabled, .ant-select-disabled .ant-select-selector .ant-select-selection-item, .ant-picker .ant-picker-input >input[disabled]{
      color: #656565;
      background-color: #f4f4f4;
    }
  }
}


.newWarrantyForm{

    .ant-form-item{
        margin-bottom: 0px;
    }

    .ant-row {
        margin-bottom: 10px;
    }

    .ant-form-item-label{
        padding: 0px 0 2px;
    } 

    
}


.editable-cell {
    position: relative;
  }
  
  .editable-cell-value-wrap {
    padding: 5px 12px;
    cursor: pointer;
  }
  
  .editable-row:hover .editable-cell-value-wrap {
    padding: 4px 11px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }