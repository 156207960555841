.clientsWrappers{


    .clientFilters{

        .filterButtons{          
            display: flex;
            flex-direction: row;
            justify-content: flex-end;  
          }

          .ant-form-item{
            margin-bottom: 0px;
          }

    }

    .clientDetails {
        .ant-divider {
            margin: 10px 0;
        }

        .privacyItem{            
                width: 100%;
                .ant-space-item{
                    width: 100%;
                }

                .collapseSpace{
                   width: auto;
                }               
            
        }
    }

}

