.productsWrappers{


    .productsFilters{

        .filterButtons{          
            display: flex;
            flex-direction: row;
            justify-content: flex-end;      
          }

          .ant-form-item{
            margin-bottom: 0px;
          }


    }
}

.productDetails {
    .ant-divider {
        margin: 10px 0;
    }
}