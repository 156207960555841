.promotionsWrappers {

  .promotionsFilters {

    .filterButtons {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }

    .ant-form-item {
      margin-bottom: 0px;
    }

  }

  }

  .promotionDetails {
    .ant-card-body {
      .ant-divider-horizontal {
        margin: 10px 0;
      }

      .ant-form-item {
        margin-bottom: 20px;
      }
    }
  }

.promoCodeBox {
  .ant-input {
    width: 80px;
    border-radius: 1px !important;
    font-size: 23px !important;
    font-weight: 600 !important;
    padding: 5px !important;
  }

  .ant-input-disabled {
    color: #1f1e1e;
  }

  .ant-input-affix-wrapper {
    width: 80px;
    padding: 2px 3px;
  }

  .ant-input-prefix {
    margin-inline-end: 10px;
    font-size: 15px;
  }

  .mr10 {
    margin-right: 10px;
  }
}

.promoCodeDisplay {

  h2 {
    line-height: 0;
  }
}

.codPromo {
  .ant-form-item-label {
    display: flex;
    align-items: center;
  }
}